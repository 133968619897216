.search-input {
  width: 300px;
  padding: 8px 10px 8px 20px;
  font-size: 16px;
  border: 1px solid rgba(217, 43, 43, 0.5);
  border-radius: 30px;
  background-color: #ecf0f1;
  outline: none;
  font-weight: 300;
  &:focus {
    outline: none;
    border: 1px solid rgba(217, 43, 43, 0.9);
  }
}