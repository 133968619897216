.loading {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;

  .loading-item {
    position: relative;
    width: 100%;
    height: 100%;
    .loading-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 30%;
      left: 50%;
    }
  }
}