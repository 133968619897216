
.image-logo{
  width: 100px;
  img{
  width: 100;
}
}

.user{
  p{
    font-size: 12px;
  }

  .admin{
    font-weight: bold;
  }

}