.card {
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 20px;
}

.card-header {
  font-weight: bold;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.card-body {
  margin-bottom: 20px;
}

.card-footer {
  padding-top: 15px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}