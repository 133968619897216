.userimage{
  height: 70px;
  flex-shrink: 0;
  border-radius: 10px;
  img{
    width: 100%;
  }
}

.members-search-bar {
  .search-input {
    position: relative;
    top: -20px;
  }
}

.pagination {
  list-style: none;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
  
  li {
    margin-right: 20px;
    border: 1px solid #D92B2B;
    border-radius: 50%;
    a {
      padding: 0px 13px;
      line-height: 32px;
    }
    &.active {
      background-color: #D92B2B;
      color: white;
    }

    &.previous,
    &.next{
      background-color: white;
      border-radius: 10px;
      border: 1px solid #D92B2B;

      &.disabled {
        @apply border-gray-400;
      }
    }
  }
}

.member-item {
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .label {
    color: #D92B2B;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .actions {
    .action {
      padding: 10px 20px;
      border-radius: 30px;
      border: 1px solid #000;
  
      &.register {
        border: 1px solid #D92B2B;
        color: #D92B2B;
      }
    }
  }
}