.btn-report {
  margin-right: 30px;

  background-color: #D92B2B;
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
}

.btn-add-filter {
  margin-bottom: 30px;

  background-color: #D92B2B;
  border-radius: 5px;
  padding: 10px 15px;
  color: white;
}