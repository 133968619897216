.submit-input {
  background-color: #D92B2B;
  padding: 10px 25px;
  color: white;
  border-radius: 10px;
}

.add-dependente {
  background-color: #D92B2B;
  padding: 10px 25px;
  color: white;
  border-radius: 10px;
}