.tab-titles {
  margin-bottom: 40px;
}

.tab-title {
  margin-right: 30px;
  padding: 0 20px 5px 20px;
  &.tab-title--active {
    font-weight: 600;
    color: #D92B2B;
    border-bottom: 3px solid #D92B2B;
  }
  &:last-child {
    margin-right: 0;
  }
}

.tab-content {

}