.modal-overlay {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
align-items: center;
}

.modal {
  z-index: 1000;
  width: 200px;
  height: 200px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;

  .icon-success {
    color: green;
    width: 40px;
    height: 40px;
    margin-bottom: 40px;
  }

  .icon-error {
    color: red;
    width: 40px;
    height: 40px;
    margin-bottom: 40px;
  }
}